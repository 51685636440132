import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-200 py-6 mt-8 px-10">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
        <div className="text-center md:text-left">
          <p className="text-black text-sm">
            &copy; {new Date().getFullYear()} Elancer. All rights reserved.
          </p>
        </div>
        <div className="text-center md:text-right mt-2 md:mt-0">
          <p className="text-black text-sm">
            Contact: info@elancer.pk 
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
