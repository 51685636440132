import './App.css';
import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import JobApplicationForm from './components/JobApplicationForm';
import Loader from './components/Loader';
// import TestConditions from './components/TestConditions';

function App() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading for 5 seconds
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    // Clean up the timeout when the component unmounts
    return () => clearTimeout(timeout);
  }, []);


  return (
      <BrowserRouter>
      {isLoading ? (
        <Loader /> 
      ) : (
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/careers' element={<JobApplicationForm />} />
          {/* <Route path='/resumes' element={<Resume />} /> */}
          {/* <Route path='/test' element={<TestConditions/>} /> */}
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;
