import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCloudUploadAlt } from "react-icons/fa";
import axios from "axios";
import Modal from "./Modal";
import careers from "../assets/caereers.jpg"
import logo from "../assets/logo.png"
const JobApplicationForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    position: "",
    startDate: null,
    interviewDate: null,
    coverLetter: "",
    resume: null,
    otherDocuments: null,
  });
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const academicCategories = ["Essay Writing", "Academic Writing", "Article Writing"];


  const validateForm = () => {
    let tempErrors = {};
    if (!formData.name) tempErrors.name = "Name is required.";
    if (!formData.email) tempErrors.email = "Email is required.";
    if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email)) {
      tempErrors.email = "Email is not valid.";
    }
    
    if (!formData.phone) tempErrors.phone = "Phone number is required.";
    if (!formData.position) tempErrors.position = "Position is required.";
    if (!formData.startDate) tempErrors.startDate = "Start date is required.";
    if (!formData.interviewDate)
      tempErrors.interviewDate = "Interview date is required.";
    if (!formData.coverLetter)
      tempErrors.coverLetter = "Cover letter is required.";
    if (!formData.resume) tempErrors.resume = "Resume is required.";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    if (errors[name]) setErrors({ ...errors, [name]: null });
  };

  const handleDateChange = (name, date) => {
    handleChange(name, date);
  };

  const handleFileChange = (name, file) => {
    if (file) {
      // Validate file type if needed (e.g., only accept PDFs)
      const allowedFileTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
      if (!allowedFileTypes.includes(file.type)) {
        setErrors({
          ...errors,
          [name]: "Invalid file type. Please upload a PDF file.",
        });
        return;
      }

      // Clear the previous error if any
      setErrors({
        ...errors,
        [name]: null,
      });

      handleChange(name, file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend.append(key, value instanceof File ? value : value || "");
    });

    try {
      setLoader(true);
      const response = await axios.post(
        "https://backend.elancer.pk/api/submitApplication",
        formDataToSend
      );
      console.log(response.data);
      setFormData({
        name: "",
        email: "",
        phone: "",
        position: "",
        startDate: null,
        interviewDate: null,
        coverLetter: "",
        resume: null,
        otherDocuments: null,
      });
      setIsModalOpen(true);
    } catch (error) {
      console.error(error);
      alert("Failed to submit application.");
    } finally {
      setLoader(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div
    style={{
      backgroundImage: `url(${careers})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      position:  "relative",
      zIndex: 1
    }}
  >
      <div className="max-w-2xl mx-auto p-6 bg-black opacity-90 shadow-2xl rounded-md mt-6">
        <div className="text-center mb-6">
        <img src={logo} className="h-20 mx-auto my-auto" alt="logo" />
          <h2 className="text-2xl text-white font-semibold">Job Application Form</h2>
          <p className="text-white">Please Fill Out the Form Below to Submit Your Job Application!</p>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Name Input */}
          <div className="mb-4">
            <label htmlFor="name" className="text-white block">
              Name *
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              className={`mt-1 block w-full border p-2 rounded-md ${
                errors.name ? "border-red-500" : "border-gray-300"
              }`}
              placeholder="John Doe"
            />
            {errors.name && (
              <p className="text-red-500 text-xs mt-1">{errors.name}</p>
            )}
          </div>

          {/* Email Input */}
          <div className="mb-4">
            <label htmlFor="email" className="text-white block">
              Email *
            </label>
            <input
              type="text"
              name="email"
              id="email"
              value={formData.email}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              className={`mt-1 block w-full border p-2 rounded-md ${
                errors.email ? "border-red-500" : "border-gray-300"
              }`}
              placeholder="john.doe@example.com"
            />
            {errors.email && (
              <p className="text-red-500 text-xs mt-1">{errors.email}</p>
            )}
          </div>

          {/* Phone Input */}
          <div className="mb-4">
            <label htmlFor="phone" className="text-white block">
              Phone *
            </label>
            <input
              type="text"
              name="phone"
              id="phone"
              value={formData.phone}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              className={`mt-1 block w-full border p-2 rounded-md ${
                errors.phone ? "border-red-500" : "border-gray-300"
              }`}
              placeholder="123-456-7890"
            />
            {errors.phone && (
              <p className="text-red-500 text-xs mt-1">{errors.phone}</p>
            )}
          </div>

          {/* Position Input */}
          <div className="mt-4">
          <label htmlFor="position" className="text-white block">
            Category for Academic Writing
          </label>
          <select
            name="position"
            id="position"
            value={formData.position}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            className={`mt-1 block w-full border p-2 rounded-md ${
              errors.position ? "border-red-500" : "border-gray-300"
            }`}
          >
            <option value="">Select a Category</option>
            {academicCategories.map((position) => (
              <option key={position} value={position}>
                {position}
              </option>
            ))}
          </select>
          {errors.position && (
            <p className="text-red-500 text-xs mt-1">{errors.position}</p>
          )}
        </div>

          {/* Start Date Input */}
          <div className="mb-4">
            <label htmlFor="startDate" className="text-white block">
              Start Date *
            </label>
            <DatePicker
              selected={formData.startDate}
              onChange={(date) => handleDateChange("startDate", date)}
              className={`mt-1 block w-full border p-2 rounded-md ${
                errors.startDate ? "border-red-500" : "border-gray-300"
              }`}
              placeholderText="Select start date"
            />
            {errors.startDate && (
              <p className="text-red-500 text-xs mt-1">{errors.startDate}</p>
            )}
          </div>

          {/* Interview Date Input */}
          <div className="mb-4">
            <label htmlFor="interviewDate" className="text-white block">
              Interview Date and Time *
            </label>
            <DatePicker
              selected={formData.interviewDate}
              onChange={(date) => handleDateChange("interviewDate", date)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="MMMM d, yyyy h:mm aa"
              className={`mt-1 block w-full border p-2 rounded-md ${
                errors.interviewDate ? "border-red-500" : "border-gray-300"
              }`}
              placeholderText="Select interview date and time"
            />
            {errors.interviewDate && (
              <p className="text-red-500 text-xs mt-1">
                {errors.interviewDate}
              </p>
            )}
          </div>

          {/* Cover Letter Textarea */}
          <div className="mb-4">
            <label htmlFor="coverLetter" className="text-white block">
              Cover Letter *
            </label>
            <textarea
              name="coverLetter"
              id="coverLetter"
              value={formData.coverLetter}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              className={`mt-1 block w-full border p-2 rounded-md ${
                errors.coverLetter ? "border-red-500" : "border-gray-300"
              }`}
              placeholder="Your cover letter"
              rows="4"
            />
            {errors.coverLetter && (
              <p className="text-red-500 text-xs mt-1">{errors.coverLetter}</p>
            )}
          </div>

          {/* Resume File Upload */}
          <div className="mb-4">
            <label htmlFor="resume" className="text-white block">
              Resume *
            </label>
            <label
              className={`mt-1 text-white  w-full border p-2 rounded-md flex justify-between items-center ${
                errors.resume ? "border-red-500" : "border-gray-300"
              }`}
            >
              <span>
                {formData.resume ? formData.resume.name : "No file chosen"}
              </span>
              <FaCloudUploadAlt className="text-white h-10 " />
              <input
                type="file"
                name="resume"
                id="resume"
                onChange={(e) =>
                  handleFileChange(e.target.name, e.target.files[0])
                }
                className="hidden"
              />
            </label>
            {errors.resume && (
              <p className="text-red-500 text-xs mt-1">{errors.resume}</p>
            )}
          </div>

          {/* Submit Button */}
          <div>
            <button
              type="submit"
              className="w-full flex justify-center bg-green-500 text-white p-2 rounded-md hover:bg-green-600 transition-colors duration-300"
            >
              {loader ? "Loading..." : "Apply"}
            </button>
          </div>
        </form>
        <Modal isOpen={isModalOpen} closeModal={closeModal} />
      </div>
    </div>
  );
};

export default JobApplicationForm;
