import React from "react";
import { FaTwitter, FaFacebook, FaInstagram, FaEnvelope, FaLinkedin } from "react-icons/fa";
import logo from "../assets/logo.png";
const Navbar = () => {
  return (
    <div>
      <header className="body-font px-10">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <a
            href="/"
            className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
          >
            <img src={logo} alt="logo" className="ml-3 max-h-24"></img>
          </a>
          <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-gray-400 flex flex-wrap items-center text-base justify-center">
            {/* Add your navigation items here if needed */}
          </nav>
          <div className="flex items-center space-x-4">
            <a href="https://twitter.com/elancerpk">
              <FaTwitter
                className="text-green-500 hover:text-green-800 cursor-pointer"
                size={20}
              />
            </a>
            <a
              href="https://www.facebook.com/ELancerPkOfficial"
            >
              {" "}
              <FaFacebook
                className="text-green-500 hover:text-green-800 cursor-pointer"
                size={20}
              />{" "}
            </a>
            <a href="https://www.instagram.com/elancer.pk">
            <FaInstagram
              className="text-green-500 hover:text-green-800 cursor-pointer"
              size={20}
            /> </a>
             <a href="https://www.linkedin.com/company/elancerpk/">
              <FaLinkedin
                className="text-green-500 hover:text-green-800 cursor-pointer"
                size={20}
              />
            </a>
            <div className="flex items-center space-x-3">
            <a href="mailto:hr@elancer.pk">
 <FaEnvelope
                className="text-green-500 hover:text-green-800 cursor-pointer"
                size={20}
              /> </a>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
