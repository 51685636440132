import React from 'react';
import { FaBuilding, FaClock } from 'react-icons/fa';

const JobInformation = () => {
  return (
    <section className="text-gray-600 body-font px-2 mt-12">
      <div className="container mx-auto flex flex-wrap items-start justify-start">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
          {/* Job Category */}
          <div className="flex items-center mb-4">
            <FaBuilding className="w-6 h-6 mr-2" />
            <span className="text-gray-700">Category</span>
            <span className="ml-2 font-bold">Academic Writing</span>
          </div>

          {/* Job Type */}
          <div className="flex items-center mb-4">
            <FaBuilding className="w-6 h-6 mr-2" />
            <span className="text-gray-700">Job Type</span>
            <span className="ml-2 font-bold">Remote</span>
          </div>

          {/* Posted On */}
          <div className="flex items-center mb-4">
            <FaClock className="w-6 h-6 mr-2"/>
            <span className="text-gray-700">Posted on</span>
            <span className="ml-2 font-bold">January 12, 2024</span>
          </div>

          <div className="flex items-center mb-4">
            <FaClock className="w-6 h-6 mr-2" />
            <span className="text-gray-700">Experience</span>
            <span className="ml-2 font-bold">+1 Years</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JobInformation;
