// Loader.js
import React from 'react';
import logo from "../assets/logo.png"
const Loader = () => {

 

  return   (
    <div className="loader">
      {/* Add your loader image with transitions */}
      <img
      src={logo}
        alt="Loading..."
        className="loader-image"
      />
    </div>
  ) 
};

export default Loader;
