import React from 'react'
import HeroSection from './HeroSection'
import JobListing from './JobListing'
import Footer from './footer'

const Home = () => {
  return (
  <>

  <HeroSection/>
  <JobListing/>
  <Footer/>
  </>
  )
}

export default Home
