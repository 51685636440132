import React from "react";
import JobInformation from "./JobInformation";
import { Link } from "react-router-dom";
import { BsCheckCircle } from "react-icons/bs"; // Import the check circle icon

const JobListing = () => {
  return (
    <>
      <div className="max-w-5xl mx-auto p-6 bg-white rounded-md shadow-md">
        <JobInformation />

        <div className="grid grid-cols-1  gap-6">
          <div>
            <div className="mt-8">
              <h3 className="text-2xl font-semibold mb-3 text-gray-800">
                Qualities to Qualify for the Job
              </h3>
              <div className="space-y-2 text-gray-700">
                <p>
                  <BsCheckCircle className="text-green-500 inline mr-2" /> University graduate
                </p>
                <p>
                  <BsCheckCircle className="text-green-500 inline mr-2" /> About 2 years experience in the writing field
                </p>
                <p>
                  <BsCheckCircle className="text-green-500 inline mr-2" /> Creative English writing skills
                </p>
                <p>
                  <BsCheckCircle className="text-green-500 inline mr-2" /> SEO-friendly content
                </p>
                <p>
                  <BsCheckCircle className="text-green-500 inline mr-2" /> Deliver human-written material
                </p>
                <p>
                  <BsCheckCircle className="text-green-500 inline mr-2" /> Time management
                </p>
                <p>
                  <BsCheckCircle className="text-green-500 inline mr-2" /> Can deliver 2500 words per day
                </p>
              </div>
            </div>

            {/* Job Requirements Section */}
            <div className="mt-8">
              <h3 className="text-2xl font-semibold mb-3 text-gray-800">
                Job Requirements
              </h3>
              <p className="text-black text-md">
                We are hiring a dedicated academic writer who is well aware of
                the writing rules and regulations and can generate SEO-friendly
                content on demand. We highly encourage people who have writing
                experience in diverse fields with the following qualities:
              </p>
              <div className="space-y-2 mt-4 text-gray-700">
                <p>
                  <BsCheckCircle className="text-green-500 inline mr-2" /> Proficient in the English language, provides human written content, and can work independently on diverse writing projects.
                </p>
                <p>
                  <BsCheckCircle className="text-green-500 inline mr-2" /> Proficient in computers especially, in writing tools and programs such as Microsoft Word, Google Docs, etc.
                </p>
                <p>
                  <BsCheckCircle className="text-green-500 inline mr-2" /> Have time management skills with organization, prioritizing, and adapting when required.
                </p>
                <p>
                  <BsCheckCircle className="text-green-500 inline mr-2" /> Strictly adhere to writing guidelines and provide well-researched content for multiple platforms.
                </p>
                <p>
                  <BsCheckCircle className="text-green-500 inline mr-2" /> Able to communicate with the teams such as the writing team, and content manager to work effectively.
                </p>
              </div>
            </div>

            {/* About Company Section */}
            <div className="mt-8">
              <h3 className="text-2xl font-semibold mb-3 text-gray-800">
                Who we are?
              </h3>
              <p className="text-gray-600 text-md">
                We are a leading company producing engaging content for our
                clients. We seek individuals with a writing passion who have a
                wish to excel in the writing field with attractive earnings.
              </p>
            </div>
            <div className="flex mt-6 gap-2">
              <Link to="/careers">
                <p className="flex-shrink-0 text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-800 rounded text-lg mt-4 sm:mt-0 whitespace-nowrap">
                  Apply Now
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobListing;
