import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import image1 from "../assets/image1.jpg";
import image2 from "../assets/image2.jpg";
import Navbar from "./Navbar";

const HeroSection = () => {
  const [currentImage, setCurrentImage] = useState(image1);

  useEffect(() => {
    const images = [image1, image2];

    const intervalId = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * images.length);
      setCurrentImage(images[randomIndex]);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${currentImage})`,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background
          color: "white",
          zIndex: 1,
        }}
      >
        <Navbar />
        <section className="body-font">
          <div className="hero-content">
            <div className="container px-5 lg:py-20 mx-auto">
              <div className="lg:w-3/4 flex flex-col sm:flex-row sm:items-center items-start mx-auto p-8">
                <div className="sm:pr-8 mb-4 sm:mb-0">
                  <h1 className="text-3xl md:text-4xl font-bold text-white mb-4">
                    Looking for Academic Writing Talent
                  </h1>
                  <p className="text-white">
                    If you are an academic writer and looking for a source to
                    display your talent and earn money in return, we are here to
                    provide you with an opportunity to polish your writing
                    skills with an attractive earning package.
                  </p>
                </div>
                <Link to="/careers">
                  <p className="flex-shrink-0 text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-800 rounded text-lg mt-4 sm:mt-0 whitespace-nowrap">
                    Apply Now
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default HeroSection;
